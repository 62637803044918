import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Redirect = () => (
  <Helmet>
    <meta
      httpEquiv="refresh"
      content="1; URL=https://www.prooftimetracking.com"
    />
  </Helmet>
)

export default Redirect
