import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/templates/Layout/Layout"
import PageWidth from "../components/templates/PageWidth/PageWidth"
import Redirect from "../features/Redirect/Redirect"
import SEO from "../components/atoms/SEO/SEO"
import SmartLink from "../components/atoms/SmartLink/SmartLink"

const HomePage = (props: PageProps) => (
  <Layout>
    <SEO title="Home" />
    <Redirect />
    <PageWidth>
      <h1>Keep going...</h1>
      <SmartLink to="https://www.prooftimetracking.com">
        to the official proof website
      </SmartLink>
    </PageWidth>
  </Layout>
)

export default HomePage
